<template>
  <div>
    <v-card>
      <v-card-text>
        <DateTimePicker
          @getDateFrom="dateFrom = $event"
          @getDateTo="dateTo = $event"
        ></DateTimePicker>

        <br>
        <v-row
          v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
        >
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="advertiserId"
              :items="advertisers"
              item-text="name"
              item-value="id"
              :label="$t('Advertiser')"
              :disabled="loadingFirst"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingFirst"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              ref="camp"
              v-model="campaignIds"
              :items="campaigns"
              item-text="name"
              item-value="id"
              :label="$t('CampaignFilter')"
              :disabled="loadingCampaign"
              multiple
              clearable
              hide-details
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  :input-value="data.selected"
                  close
                  @click:close="campaignIds = handleRemoveFromArray(campaignIds, data.item.key)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingCampaign"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-btn
          color="primary"
          @click="getDataFromDB"
        >
          {{ $t('BtnSearch') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <br>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataFromDb"
        item-key="name"
        class="elevation-1"
        :sort-by.sync="orderBy"
        :sort-desc.sync="sortDesc"
        disable-sort
        :search="search"
        :loading="loading"
        :locale="$i18n.locale"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-8">
              <div class="container">
                <v-btn
                  v-if="$checkAbility('CAN_DOWNLOAD_REPORT')"
                  outlined
                  color="primary"
                  @click="exportToExcel()"
                >
                  {{ $t('VtrReportElements.Download') }}
                </v-btn>
              </div>
            </div>
            <div class="col-4">
              <v-text-field
                v-model="search"
                :label="$t('LblSearch')"
                class="mx-4"
              ></v-text-field>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import qs from 'qs'
import DateTimePicker from '../Components/DateTimePicker.vue'

export default {
  setup() {
    const select = ref()
    const items = []

    return { select, items }
  },
  components: {
    DateTimePicker,
  },
  data() {
    return {
      orderBy: 'advertiserName',
      sortDesc: false,
      search: '',
      advertisers: [],
      advertiserId: null,
      campaigns: [],
      loadingCampaign: false,
      loadingFirst: false,
      showMessage: false,
      messageText: '',
      dataFromDb: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      campaignIds: [],
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('VtrReportElements.Advertiser'), value: 'advertiserName', isForExcel: true },
        { text: this.$t('VtrReportElements.CampaignName'), value: 'campaignName', isForExcel: true },
        { text: this.$t('VtrReportElements.CreativeName'), value: 'creativeName', isForExcel: true },
        { text: this.$t('VtrReportElements.Clicks'), value: 'clicks', isForExcel: true },
        { text: this.$t('VtrReportElements.Views'), value: 'views', isForExcel: true },
        { text: this.$t('VtrReportElements.ViewTo25'), value: 'viewsTo25', isForExcel: true },
        { text: this.$t('VtrReportElements.ViewTo50'), value: 'viewsTo50', isForExcel: true },
        { text: this.$t('VtrReportElements.ViewTo75'), value: 'viewsTo75', isForExcel: true },
        { text: this.$t('VtrReportElements.ViewTo100'), value: 'viewsTo100', isForExcel: true },
      ]
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.getDataFromDB()
    },
    advertiserId() {
      this.campaignIds = []
      this.getCampaigns(false)
    },
  },
  mounted() {
    this.firstLoad()
  },
  methods: {
    exportToExcel() {
      let cols = [
        { width: 20 },
        { width: 50 },
        { width: 15 },
        { width: 12 },
        { width: 12 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
      ]

      this.$exportToExcel(
        this.headers,
        this.dataFromDb,
        `${this.$t('VTRExcelHeader')}.xlsx`,
        `${this.$t('VTRExcelHeader')}`,
        cols,
      )
    },
    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data
          if (this.firstLoad === true) {
            this.advertiserId = null
          }

          this.loadingFirst = false
        })
    },

    getCampaigns(isFirstLoad) {
      this.loadingCampaign = true

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false
        })
        .catch(error => {
          console.log(error)
        })
    },

    getDataFromDB() {
      this.loading = true

      let campaigns = []
      if (this.campaignIds != null && this.campaignIds.length > 0) {
        campaigns = this.campaignIds
      }
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      console.log(this.campaignIds)
      const params = {
        campaignIds: campaigns,
        advertiserId: this.advertiserId,
        dateFrom: dateStart,
        dateTo: dateFinish,
      }

      this.$http
        .get(`${this.$apiBaseURL}/vtr/report?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
        .then(response => {
          this.dataFromDb = response.data == null ? [] : response.data
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    firstLoad() {
      this.getAdvertisers().then(() => {
        this.getCampaigns(true).then(() => {
          this.getDataFromDB()
        })
      })
    },

    handleRemoveFromArray(array, element) {
      return array.filter(ele => ele !== element)
    },
  },
}
</script>
